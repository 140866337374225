import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div style={{ paddingTop: `200px`, paddingBottom: `100px`, backgroundColor: `#005f86` }}>
      <div className="container">
        <div className="row">
          <div class="col-12">
            <h1 style={{ color: `white`, fontWeight: `600` }}>404: Not Found</h1>
            <p style={{ color: `white`, fontSize: `1.5rem` }}>You just hit a route that doesn&#39;t exist.</p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
